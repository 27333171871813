* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
}

.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
}

.common-pointer {
  cursor: pointer;
}

.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}

.dhiwise-navigation h1 {
  color: white;
}

.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}

.dhiwise-navigation ul {
  padding: 0;
}

.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.dhiwise-navigation a {
  color: #132cdc;
}

table {
  border-spacing: 0;
  width: 100%;
}

.common-select {
  z-index: 10000;
}

.react-datepicker-popper {
  z-index: 100000 !important;
}

.drawable-icon {
  position: absolute;
  margin: auto;
  z-index: 1000;
}

.input-wrap {
  position: relative;
}

option {
  color: #000;
}

.table-wrap {
  overflow: auto;
}

input:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.slider-indicator:first-child {
  margin-left: 0;
}

.slider-indicator:last-child {
  margin-right: 0;
}

.rc-drawer-content-wrapper {
  width: 100% !important;
}

.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}

.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 1051;
}

ul li a {
  display: block;
}

.mobile-menu div {
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin: 4px 0;
}

.mobile-menu {
  display: none;
  cursor: pointer;
}

.header-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .mobile-menu {
    display: block;
  }

  .header-row {
    width: 100% !important;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .common-row-list>ul {
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}

.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.menu-overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}

.close-line1 {
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.close-line2 {
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.common-row-list>ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list>ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li {
  width: 100%;
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}

.top-nav {
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.top-nav button,
.top-nav>div {
  padding: 9px 16px !important;
  min-width: auto !important;
  font-size: 16px !important;
  line-height: 1;
  border-bottom: 4px solid transparent;
}

.top-nav>div h3 {
  font-size: 16px !important;
  line-height: 1;
  margin: 0px;
  padding: 0px;
}

.top-nav .border-blue_A400 {
  border-bottom: 4px solid #297eff;
}


.form-wrap.gap-5 {
  gap: 10px;
}

.form-wrap .gap-2\.5 {
  gap: 5px !important;
}

.form-wrap .w-full.rounded-md {
  height: 30px;
  padding: 2px 5px 0px 10px;
  border-radius: 4px;
  border-color: #bcc6d0 !important;
}

.form-wrap h3 {
  font-size: 16px;
}

.update-fixed {
  position: fixed;
  bottom: 0px;
  padding: 10px;
  background: white;
  left: 0px;
  border-top: 1px solid #eee;
  padding-left: 16px;
}

.enquery_list {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.text_success {
  color: #1e7e34;
  margin-left: 5px;
}

.remove_focus:focus {
  outline: none !important;
}

.text_warning {
  color: #ff5722;
  margin-left: 5px;
}

/* .View_in_MS_Dynamics {
  font-size: 12px !important;
}

.add_transcript {
  font-size: 12px !important;
} */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  /* Light grey */
  border-top: 2px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: default;
}


.custom-container {
  padding-left: 16px;
  padding-right: 16px;
}

button.bg-blue_A400 {
  padding: 7px 12px 8px 12px !important;
  border-radius: 5px;
  font-size: 15px;
}

.section-title {
  padding-bottom: 8px !important;
}

.search-list .list-group-item {
  padding: 8px 20px;
  border: 1px solid #dee2e6;
  border-radius: 8px !important;
  font-size: 14px;
}

.search-list.list-group {
  gap: 10px;
}

.search-list .name {
  font-weight: 700;
  color: #000;
}

.edit-contact-wrap {

  padding: 21px 0px 10px 0px !important;



}

.text-16 {
  font-size: 16px !important;
}

.enquiry-list-item {
  padding-bottom: 8px;
  border-bottom: 1px solid #dee2e6 !important;
}

.enquiry-list-item .box {
  padding: 8px 8px;
  border: 1px solid #dee2e6 !important;
  border-radius: 8px !important;
  font-size: 14px;
}

.add-transcript-btn {
  border-radius: 5px;
  font-size: 15px;
  padding: 6px 8px 7px !important;
  border-color: #4384f5 !important;
}

.react-datepicker-wrapper {
  border: 1px solid #dee2e6 !important;
  padding: 3px;
  border-radius: 4px;
  border-color: #bcc6d0 !important
}

.mobile_input {
  height: 30px;
  padding: 2px 5px 0px 10px !important;
  border-radius: 4px !important;
  border-color: #bcc6d0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}