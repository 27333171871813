:root { 
--gray_700:#845b5a; 
--blue_gray_200:#b8c1d3; 
--gray_500:#979797; 
--blue_gray_400:#8691a5; 
--blue_A400:#297eff; 
--blue_gray_200_4c:#b8c1d34c; 
--blue_gray_200_7f:#b8c1d37f; 
--gray_50:#f8f8f9; 
--black_900_1e:#0000001e; 
--green_400:#4bb679; 
--blue_gray_200_33:#b8c1d333; 
--blue_gray_700:#535866; 
--blue_gray_900:#262a2e; 
--white_A700:#ffffff; 
}